// Import dependencies
import * as cocossd from '@tensorflow-models/coco-ssd';
import { AppContext } from 'context/appContext';
import {
	SettingHeaders,
	SettingPageHeaders,
	SettingPageSectionHeaders,
	SettingSectionHeaders,
	SettingTabHeaders,
} from 'hooks/useSettingsController';
import { useContext, useEffect, useRef } from 'react';
import './index.scss';

export enum ObjectDetectionLibraries {
	COCO_SSD = 'tensorflow/coco-ssd',
	MEDIAPIPE = 'mediapipe',
}

// Define confidence thresholds
const CONFIDENCE_THRESHOLD = 0.5; // minimum confidence score to consider a gesture

type ObjectDetectionCallbacks = {
	zoomTo: (...args: any[]) => any;
};
type ObjectDetectionOptions = {
	faceTracking?: boolean;
};

class _COCO_SSD_LIBRARY_CONTROLLER {
	private net: cocossd.ObjectDetection | null = null;
	private detectionIntervalId: ReturnType<typeof setInterval>;
	private zoomedOut: boolean = true;
	private sequenceNumber: number;
	private trackFace = (detections: cocossd.DetectedObject[]) => {
		const personDetections = detections.filter((detection) => detection.class === 'person');
		if (personDetections.length) {
			const scale = this.dimensions.width / personDetections[0].bbox[2];
			this.callbacks.zoomTo(
				-1 * personDetections[0].bbox[0] * scale,
				-1 * personDetections[0].bbox[1] * scale,
				scale
			);
			this.zoomedOut = false;
		} else if (!this.zoomedOut) {
			this.callbacks.zoomTo(0, 0, 1);
			this.zoomedOut = true;
		}
	};

	public drawRect = (detections: any, ctx: any) => {
		// Font options.
		const font = '16px sans-serif';
		ctx.font = font;
		ctx.textBaseline = 'top';
		detections.forEach((detection: any) => {
			const [x, y, width, height] = detection.bbox;
			// Draw the bounding box.
			ctx.strokeStyle = '#00FFFF';
			ctx.lineWidth = 4;
			ctx.strokeRect(x, y, width, height);
			// Draw the label background.
			ctx.fillStyle = '#00FFFF';
			const textWidth = ctx.measureText(detection.class).width;
			const textHeight = parseInt(font, 10); // base 10
			ctx.fillRect(x, y, textWidth + 4, textHeight + 4);
			// Draw the text last to ensure it's on top.
			ctx.fillStyle = '#000000';
			ctx.fillText(detection.class, x, y);
		});
	};

	public detect = async () => {
		let nowInMs = Date.now();
		// Check data is available
		if (
			this.net &&
			this.video?.readyState === 4 &&
			this.dimensions.width > 0 &&
			this.dimensions.height > 0
		) {
			// Make Detections
			const detections = await this.net.detect(this.video);
			// Correct position and scale
			const xRatio = this.dimensions?.width / this.video?.videoWidth;
			const yRatio = this.dimensions?.height / this.video?.videoHeight;
			detections.forEach((detection) => {
				if (detection.class === 'person' && detection.score >= CONFIDENCE_THRESHOLD) {
					this.publish(detection.class, detection.bbox, detection.score, nowInMs);
				}

				detection.bbox[0] = detection.bbox[0] * xRatio;
				detection.bbox[1] = detection.bbox[1] * yRatio;
				detection.bbox[2] = detection.bbox[2] * xRatio;
				detection.bbox[3] = detection.bbox[3] * yRatio;
			});

			// Draw mesh
			const ctx = this.canvasRef?.current?.getContext('2d');
			ctx?.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
			this.drawRect(detections, ctx);

			// Callbacks
			if (this.options.faceTracking) this.trackFace(detections);
			else if (!this.zoomedOut) {
				this.callbacks.zoomTo(0, 0, 1);
				this.zoomedOut = true;
			}
		}
	};

	public load = () => {
		cocossd.load().then((res) => {
			console.log('Object detection model loaded');
			this.net = res;
			this.sequenceNumber = 0;
			this.start();
		});
	};

	public start = () => {
		if (this.net && this.dimensions.width > 0 && this.dimensions.height > 0) {
			clearInterval(this.detectionIntervalId!);
			this.detectionIntervalId = setInterval(() => {
				requestAnimationFrame(() => this.detect());
			}, this.detectionRate);
			console.log('Object detection model started');
		}
	};

	public unload = () => {
		clearInterval(this.detectionIntervalId!);
		this.net?.dispose();
		console.log('Object detection model unloaded');
		this.callbacks.zoomTo(0, 0, 1);
	};

	public publish = (
		detected_object: string,
		bounding_box: [number, number, number, number],
		confidence_score: number,
		timestamp: number
	) => {
		if (
			(window as any).webRTCSession &&
			(window as any).webRTCSession.dataChannels[1] &&
			(window as any).webRTCSession.dataChannels[1].readyState === 'open'
		) {
			let message = JSON.stringify({
				type: 'bounding_box_update',
				data: {
					sequence_number: this.sequenceNumber,
					detected_object: detected_object,
					confidence_score: confidence_score,
					bounding_box: bounding_box,
					timestamp: timestamp,
				},
			});
			console.log('Publishing to datachannel: ' + message);
			(window as any).webRTCSession.dataChannels[1].send(message);
			this.sequenceNumber = this.sequenceNumber + 1;
		}
	};

	constructor(
		public video: any,
		public dimensions: { width: number; height: number },
		public canvasRef: React.RefObject<HTMLCanvasElement>,
		public callbacks: ObjectDetectionCallbacks,
		public options: ObjectDetectionOptions,
		public detectionRate: number = 500
	) {}
}

type PropsFromParent = {
	video: any;
	dimensions: { width: number; height: number };
	callbacks: ObjectDetectionCallbacks;
	options: ObjectDetectionOptions;
};

const ObjectDetection: React.FC<PropsFromParent> = ({ video, dimensions, callbacks, options }) => {
	const { settingsController } = useContext(AppContext);
	const getLibraryController = (library: string) => {
		switch (library) {
			case ObjectDetectionLibraries.COCO_SSD:
				return _COCO_SSD_LIBRARY_CONTROLLER;
			case ObjectDetectionLibraries.MEDIAPIPE:
				return null;
		}
	};
	const library =
		settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
			SettingPageHeaders.IMAGE_RECOGNITION
		].children[SettingTabHeaders.OBJECT_DETECTION].children[SettingSectionHeaders.DETECTION]
			.children[SettingHeaders.OBJECT_DETECTION_LIBRARY].value;
	let libraryController = getLibraryController(library);

	const canvasRef = useRef<HTMLCanvasElement>(null);
	// a ref, because we don't ever change the created instance
	const controller = useRef(
		new libraryController!(video, dimensions, canvasRef, callbacks, options)
	);

	useEffect(() => {
		controller.current.load();
	}, []);

	useEffect(() => {
		return () => {
			controller.current.unload();
		};
	}, []);

	useEffect(() => {
		controller.current.options = options;
		controller.current.callbacks = callbacks;
	}, [options, callbacks]);

	return video && dimensions.width > 0 && dimensions.height > 0 ? (
		<canvas
			ref={canvasRef}
			className="detectionContainer"
			width={dimensions.width}
			height={dimensions.height}
		/>
	) : null;
};

export default ObjectDetection;
